import type { User } from '@/models/Users/User';
import type { UserPreference } from '@/models/Users/UserPreference';
import type { HttpCall, HttpClient } from '@/utils/http';
import type { Observable } from 'rxjs';

export interface UsersAPI {
  getConnectedUser$: () => Observable<HttpCall<User>>;
  getUserPreference$: () => Observable<HttpCall<UserPreference>>;
}

export const usersApiConnector = (httpClient$: HttpClient): UsersAPI => ({
  getConnectedUser$: (): Observable<HttpCall<User>> => {
    return httpClient$.getJSON<User>({
      url: '/users/me',
    });
  },
  getUserPreference$: (): Observable<HttpCall<UserPreference>> => {
    return httpClient$.getJSON<UserPreference>({
      url: '/preferences/me',
    });
  },
});
