import type { MatomoEventCategory, MatomoEvents } from '@/components/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

type EventTracker = <C extends MatomoEventCategory>(category: C, action: MatomoEvents[C]) => void;

// TODO: Setup Matomo
export const useEventTracker =
  (): EventTracker =>
  <C extends MatomoEventCategory>(category: C, action: MatomoEvents[C]): void => {
    const { trackEvent } = useMatomo();
    trackEvent({ category, action });
  };
