import { sgwtConnect } from '@/utils/sgwtConnect';
import type { HttpConfig, HttpRequestMiddleware } from '../../httpClient.models';

export const sgConnectHttpRequestMiddleware: HttpRequestMiddleware = (
  config: HttpConfig,
): HttpConfig => {
  if (!config.url.startsWith('/')) return config;

  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: sgwtConnect.getAuthorizationHeader(),
    },
  };
};
