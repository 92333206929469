import { SgwtWidgetsContext } from '@/context';
import type { SgwtMiniFooterHTMLElement } from '@/types/sgwt-widgets';
import { useContext, useRef, useEffect } from 'react';

export function MiniFooter(): JSX.Element {
  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtMiniFooterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    sgwtWidgets.setMiniFooter(ref.current);
  }, [sgwtWidgets]);

  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ref={ref}
    />
  );
}
