import type { HttpErrorResponse, RequestStatus } from '@/models';

// TODO: Move to @/models
export interface Session {
  id: string;
  saved: boolean;
  saveStatus?: RequestStatus;
  saveError?: HttpErrorResponse;
  scripts: string[];
}

export type SessionsState = Record<'new' | string, Session>;

export const initialSessionsState: SessionsState = {};
