import { useNavigate } from 'react-router-dom';

export const useGoToSession = () => {
  const navigate = useNavigate();

  return (id: string, newWindow: boolean = false): void => {
    const url = `/sessions/${id}`;

    if (newWindow) {
      // TODO: Please implement me
      window.open(url);
      return;
    }

    navigate(url);
  };
};
