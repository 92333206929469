import { getConfig } from '@/config/config';
import type { AppConfig } from '../../types/AppConfig';

export const useConfig = (): AppConfig => getConfig();

export const useSamples = <K extends keyof AppConfig['samples']>(
  type: K,
): AppConfig['samples'][K] => {
  return getConfig().samples[type];
};
