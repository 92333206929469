import { Button } from '@sgme/ui';
import { type FC, useCallback, useContext } from 'react';
import type { ButtonProps } from '@sgme/ui';
import type { IntlKey } from '@/locale';
import { Formatted } from '../Formatted';
import { SgwtWidgetsContext } from '@/context';

export type ContactUsButtonProps = Omit<ButtonProps, 'onClick'> & {
  label?: IntlKey;
};

export const ContactUsButton: FC<ContactUsButtonProps> = ({
  label = 'Contact us',
  purpose = 'primary',
  ...props
}) => {
  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const openContactUsForm = useCallback(() => {
    sgwtWidgets.helpCenter?.form();
  }, [sgwtWidgets]);

  return (
    <Formatted as={Button} id={label} purpose={purpose} onClick={openContactUsForm} {...props} />
  );
};
