import { getTheme, setTheme } from '@/utils';
import { Button, Icon } from '@sgme/ui';
import { useCallback, useMemo, useState } from 'react';
import { useEventTracker } from '@/hooks';

export const ThemeToggle: React.FC = () => {
  const [currentTheme, setCurrentTheme] = useState(getTheme);
  const trackEvent = useEventTracker();

  const isStandard = useMemo(() => currentTheme !== 'DARK', [currentTheme]);
  const buttonTitle = useMemo(
    () => (currentTheme === 'DARK' ? 'Dark theme' : 'Light theme'),
    [currentTheme],
  );

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const newTheme = currentTheme !== 'DARK' ? 'DARK' : 'LIGHT';
      setTheme(newTheme);
      setCurrentTheme(newTheme);
      trackEvent('Theme', newTheme === 'DARK' ? 'Dark theme' : 'Light theme');
    },
    [currentTheme],
  );

  return (
    <Button
      purpose="flat"
      size="lg"
      iconPosition="icon-only"
      className="ms-1"
      onClick={onClick}
      title={buttonTitle}
    >
      <Icon icon={isStandard ? 'light_mode' : 'dark_mode'} className="text-buttercup" />
    </Button>
  );
};
