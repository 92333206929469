import { type RouteObject } from 'react-router-dom';
import { HomePage } from './Home';
import { NotFoundPage } from './NotFound';
import { SessionsPage } from './Sessions';

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/home',
    element: <HomePage />,
  },
  {
    path: '/sessions/:id',
    element: <SessionsPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
