import { Button } from '@sgme/ui';
import type { FC } from 'react';
import { Formatted } from '../shared/Formatted';
import { DropdownButton } from '../shared/DropdownButton';
import { useSampleScripts } from '@/hooks';
import type { IntlKey } from '@/locale';

export const SampleScriptsButton: FC = () => {
  const scripts = useSampleScripts();

  return (
    <DropdownButton
      buttonActiveColor="info"
      buttonIcon="filter_none"
      buttonText="Sample scripts"
      className="ms-2"
      color="secondary"
      hideTextOnMobile
      iconPosition="start"
      purpose="flat"
      showToggle={false}
      size="lg"
    >
      {scripts.map(({ name }, index) => (
        <Button key={index} purpose="flat" color="secondary" size="lg" className="dropdown-item">
          <Formatted id={name as IntlKey} />
        </Button>
      ))}
    </DropdownButton>
  );
};
