import type { TypedResults } from '@/models';
import type { HttpCall, HttpClient } from '@/utils/http';
import type { Observable } from 'rxjs';

export interface QueriesAPI {
  getSuggestions$: (query: string) => Observable<HttpCall<TypedResults<string>>>;
}

export const queriesApiConnector = (httpClient$: HttpClient): QueriesAPI => ({
  getSuggestions$: (query: string): Observable<HttpCall<TypedResults<string>>> => {
    return httpClient$.getJSON<TypedResults<string>>({
      url: '/queries/do-suggest',
      queryParams: {
        query,
      },
    });
  },
});
