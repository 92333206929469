import homepageEn from './homepage.en.json';
import globalEn from './global.en.json';

const allLocales = {
  en: {
    ...globalEn,
    ...homepageEn,
  },
};

export type LocaleIsoCode = keyof typeof allLocales;

export const defaultLocale: LocaleIsoCode = 'en';

export default allLocales;

export type IntlKey = keyof typeof allLocales.en;

export type KeyPrefix<AllKeys extends string, KeySuffix extends string> = {
  [P in AllKeys]: P extends `${infer KeyPrefix}${KeySuffix}` ? KeyPrefix : never;
}[AllKeys];

export type KeySuffix<AllKeys extends string, KeyPrefix extends string> = {
  [P in AllKeys]: P extends `${KeyPrefix}${infer KeySuffix}` ? KeySuffix : never;
}[AllKeys];

declare global {
  /* eslint-disable-next-line @typescript-eslint/no-namespace */
  namespace FormatjsIntl {
    interface Message {
      ids: IntlKey;
    }
  }
}
