import { SgwtWidgetsContext } from '@/context';
import type { SgwtAccountCenterHTMLElement } from '@/types/sgwt-widgets';
import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();

  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtAccountCenterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    sgwtWidgets.setAccountCenter(ref.current);
  }, [sgwtWidgets]);

  return (
    <sgwt-account-center
      available-languages="fr,en"
      authentication="sg-connect-v2"
      mode="sg-markets"
      language={intl.locale}
      producer-code={import.meta.env.VITE_APP_ID}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ref={ref}
    />
  );
}
