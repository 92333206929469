import { useCallback, useMemo, useState, type FC, type MouseEvent } from 'react';
import { ListField } from '../shared/ListField';
import { Button } from '@sgme/ui';
import { Formatted } from '../shared/Formatted';
import { useAppDispatch, useAppSelector } from '@/store/app.hooks';
import { getQuerySuggestions, querySuggestionsRequested } from '@/store/queries';
import { getFieldOptions } from '@/utils';
import { scriptSuggestionRequested } from '@/store/scripts/scripts.slice';
import { newSessionRequested } from '@/store/sessions';
import { useNavigate } from 'react-router-dom';
import { sessionNavigated } from '@/store/users';
import classNames from 'classnames';

export interface ScriptSearchBarProps {
  id?: string;
  className?: string;
}

export const ScriptSearchBar: FC<ScriptSearchBarProps> = ({ id, className }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const suggestions = useAppSelector(getQuerySuggestions);
  const options = useMemo(() => getFieldOptions(suggestions?.results), [suggestions]);

  const [query, setQuery] = useState('');

  const onQueryConfirmed = useCallback((confirmedQuery: string) => {
    const sessionId = 'new';
    dispatch(scriptSuggestionRequested({ query: confirmedQuery }));
    dispatch(newSessionRequested(sessionId));
    dispatch(sessionNavigated(sessionId));
    navigate(`/sessions/${sessionId}`);
  }, []);

  const onInputChanged = useCallback((input: string) => {
    setQuery(input);
  }, []);

  const onInputSubmitted = useCallback(() => {
    onQueryConfirmed(query);
  }, [query]);

  const onFilter = useCallback(
    (filter?: string) => {
      if (filter === undefined) return;
      dispatch(querySuggestionsRequested(filter));
    },
    [dispatch],
  );

  const onSuggestionSelected = useCallback((value: string | undefined) => {
    if (value === undefined) return;
    onQueryConfirmed(value);
  }, []);

  const onLaunchButtonClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onQueryConfirmed(query);
    },
    [query],
  );

  return (
    <div id={`${id}-container`} className={classNames('input-group', className)}>
      <ListField<string>
        className="flex-fill w-auto"
        icon="search"
        id={id}
        noClearButton
        onFilter={onFilter}
        onSelect={onSuggestionSelected}
        onInputChange={onInputChanged}
        onInputSubmit={onInputSubmitted}
        options={options}
        placeholder="nky vol atm 6m, volswap 25000 hsi 25d, varsw..."
        showToggleButton={false}
        size="lg"
      />
      <Formatted
        id="Launch"
        as={Button}
        size="lg"
        purpose="primary"
        onClick={onLaunchButtonClicked}
      />
    </div>
  );
};
