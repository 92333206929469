import { Button, Icon } from '@sgme/ui';
import type { ButtonProps } from '@sgme/ui';
import type { FC } from 'react';
import { Formatted } from '../Formatted';
import type { IntlKey } from '@/locale';
import { useGoBack } from '@/hooks';

export type GoBackButtonProps = Omit<ButtonProps, 'onClick' | 'iconPosition'> & {
  arrowOnly?: boolean;
  label?: IntlKey;
};

export const GoBackButton: FC<GoBackButtonProps> = ({
  arrowOnly = false,
  label = 'Go back',
  purpose = 'link',
  ...props
}) => {
  const goBack = useGoBack();
  return arrowOnly ? (
    <Button purpose={purpose} iconPosition="icon-only" onClick={goBack} {...props}>
      <Icon icon="arrow_back" />
    </Button>
  ) : (
    <Formatted as={Button} id={label} purpose={purpose} onClick={goBack} {...props} />
  );
};
