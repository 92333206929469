import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { initialUsersState } from './users.model';
import { patchBy, patchObject } from '@/utils';
import type { HttpErrorResponse, User, UserPreference } from '@/models';

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  // TODO: Unit test
  reducers: {
    currentUserInfoRequested: state =>
      patchBy(state, () => ({ current: undefined, fetchUserError: undefined })),
    currentUserInfoFetched: (state, { payload }: PayloadAction<User>) =>
      patchObject(state, 'current', payload),
    currentUserInfoFetchFailed: (state, { payload }: PayloadAction<HttpErrorResponse>) =>
      patchBy(state, () => ({ current: undefined, fetchUserError: payload })),

    currentUserPrefRequested: state =>
      patchBy(state, () => ({ preferences: undefined, fetchPreferencesError: undefined })),
    currentUserPrefFetched: (state, { payload }: PayloadAction<UserPreference>) =>
      patchObject(state, 'preferences', payload),
    currentUserPrefFetchFailed: (state, { payload }: PayloadAction<HttpErrorResponse>) =>
      patchBy(state, () => ({ preferences: undefined, fetchPreferencesError: payload })),

    sessionNavigated: (state, { payload }: PayloadAction<string>) =>
      patchObject(state, 'sessionId', payload),
  },
});

export const {
  currentUserInfoRequested,
  currentUserInfoFetched,
  currentUserInfoFetchFailed,
  currentUserPrefRequested,
  currentUserPrefFetched,
  currentUserPrefFetchFailed,
  sessionNavigated,
} = usersSlice.actions;
