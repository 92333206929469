import { AccountCenter } from '@/components/sgwt';
import { useMemo, type FC } from 'react';
import { Formatted } from '@/components/shared/Formatted';
import { ThemeToggle } from '../ThemeToggle';
import { ScriptSearchBar } from '@/components/ScriptSearchBar';
import { useLocation } from 'react-router-dom';

export const AppHeader: FC = () => {
  const location = useLocation();
  const isHomepage = useMemo(() => ['/', '/home'].includes(location.pathname), [location]);

  return (
    <header className="navbar border-bottom">
      <div className="navbar-title">
        <a className="navbar-title-link" href="/">
          <div className="navbar-logo">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="32" height="32" fill="white" />
              <rect x="1" y="1" width="30" height="15" fill="var(--bs-socgen)" />
              <rect x="1" y="16" width="30" height="15" fill="black" />
              <rect x="6" y="15" width="20" height="2" fill="white" />
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            <Formatted id="Volhub" />
            <br />
            <Formatted id="Script" />
          </div>
        </a>
      </div>

      <div className="navbar-content">
        <ul className="navbar-navigation m-0 flex-fill">
          <li className="navbar-item">
            <a
              className="navbar-link"
              target="_self"
              href="https://analytics.sgmarkets.com/volhub/"
            >
              <Formatted id="Home" />
            </a>
          </li>
          {!isHomepage && (
            <li className="navbar-item my-auto mx-2 flex-fill">
              <ScriptSearchBar className="w-50" id="header-script-search-bar" />
            </li>
          )}
        </ul>
      </div>

      <div className="navbar-toolbar">
        <ThemeToggle />
        <AccountCenter />
      </div>
    </header>
  );
};
