import type { VhsEpic } from '../store';

import { catchError, map, of, switchMap } from 'rxjs';
import { usersApiConnector, type UsersAPI } from '@/api';
import { httpClient } from '@/utils/http';
import { isOfType } from '../store.utils';
import { combineEpics } from 'redux-observable';
import {
  currentUserInfoFetched,
  currentUserInfoFetchFailed,
  currentUserInfoRequested,
  currentUserPrefFetched,
  currentUserPrefFetchFailed,
  currentUserPrefRequested,
} from './users.slice';
import type { HttpErrorResponse } from '@/models';

export const currentUserInfoRequestedEpic$ =
  (api$: UsersAPI): VhsEpic =>
  actions$ =>
    actions$.pipe(
      isOfType(currentUserInfoRequested.type),
      switchMap(() =>
        api$.getConnectedUser$().pipe(
          map(res => currentUserInfoFetched(res.payload)),
          catchError((err: HttpErrorResponse) => of(currentUserInfoFetchFailed(err))),
        ),
      ),
    );

export const currentUserPrefRequestedEpic$ =
  (api$: UsersAPI): VhsEpic =>
  actions$ =>
    actions$.pipe(
      isOfType(currentUserPrefRequested.type),
      switchMap(() =>
        api$.getUserPreference$().pipe(
          map(res => currentUserPrefFetched(res.payload)),
          catchError((err: HttpErrorResponse) => of(currentUserPrefFetchFailed(err))),
        ),
      ),
    );

const usersApi$ = usersApiConnector(httpClient);

export const usersEpics$ = combineEpics(
  currentUserInfoRequestedEpic$(usersApi$),
  currentUserPrefRequestedEpic$(usersApi$),
);
