import type { IntlKey } from '@/locale';
import type { FieldOption } from '@/models';
import type { SgPicker, SgPickerChangeEvent } from '@/types/sgwt-web-components';
import type { Components } from '@sg-bootstrap/components';
import { useCallback, useEffect, useMemo, useRef, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';

export interface ListFieldProps<TValue = string> {
  className?: string;
  disabled?: boolean;
  icon?: string;
  id?: string;
  minLengthToFilter?: number;
  noClearButton?: boolean;
  onBlur?: () => void;
  onClear?: () => void;
  onFilter?: (value?: string) => void;
  onSelect?: (value: TValue | undefined) => void;
  onInputChange?: (value: string) => void;
  onInputSubmit?: () => void;
  options: FieldOption<TValue>[];
  placeholder?: IntlKey;
  readonly?: boolean;
  showToggleButton?: boolean;
  size?: Components.SgPicker['size'];
  value?: TValue;
}

export const ListField = function <TValue = string>({
  className,
  disabled = false,
  icon,
  id = v4(),
  minLengthToFilter = 3,
  noClearButton = false,
  onBlur,
  onClear,
  onFilter,
  onSelect,
  onInputChange,
  onInputSubmit,
  options,
  placeholder,
  readonly = false,
  showToggleButton = true,
  size,
  value,
}: ListFieldProps<TValue>): ReactElement<ListFieldProps<TValue>> {
  const picker = useRef<SgPicker>(null);
  const { formatMessage } = useIntl();

  const inputId = useMemo(() => `${id}-input`, [id]);
  const noClear = useMemo(() => noClearButton || value === undefined, [noClearButton, value]);
  const noIcon = useMemo(() => icon === undefined, [icon]);
  const placeholderText = useMemo(
    () =>
      placeholder ? formatMessage({ id: placeholder, defaultMessage: placeholder }) : undefined,
    [placeholder],
  );

  const onSelectItem = useCallback(
    (event: SgPickerChangeEvent<TValue>) => {
      if (value === event.detail.value) return;
      if (onSelect) onSelect(event.detail.value);
    },
    [value, onSelect],
  );

  const onInputChanged = useCallback(
    (input: CustomEvent<string>) => {
      if (onInputChange && input) onInputChange(input.detail);
      if (onFilter && input && input.detail.length >= minLengthToFilter) onFilter(input.detail);
    },
    [onFilter, onInputChange, minLengthToFilter],
  );
  const onInputSubmitted = useCallback(
    (_: CustomEvent<unknown>) => {
      if (onInputSubmit) onInputSubmit();
    },
    [onInputSubmit],
  );
  const onClearItem = useCallback(() => {
    if (onClear) onClear();
    if (onSelect) onSelect(undefined);
  }, [onClear, onSelect]);

  const onBlurInput = useCallback(
    (input: CustomEvent<TValue>) => {
      if (onBlur) onBlur();
      if (noClear && input.detail === null) {
        const selectedItem = options.find(opt => opt.value === value);
        if (selectedItem === undefined) return;
        picker.current.setValue(formatMessage({ id: selectedItem.intl }));
      }
    },
    [onBlur, formatMessage, noClear, options, value],
  );

  useEffect(() => {
    if (!picker.current) return;
    picker.current.setItems(
      options.map(({ label, intl, value }) => ({
        label: intl ? formatMessage({ id: intl, defaultMessage: intl }) : label,
        value,
      })),
    );
    if (value) picker.current.selectItemByKey(value);
    else picker.current.unselectAllItems();
  }, [formatMessage, options, value]);

  useEffect(() => {
    const ref = picker.current;

    if (ref) {
      ref.addEventListener('selectItem', onSelectItem as EventListener);
      ref.addEventListener('inputChanged', onInputChanged as EventListener);
      ref.addEventListener('inputSubmit', onInputSubmitted as EventListener);
      ref.addEventListener('clear', onClearItem as EventListener);
      ref.addEventListener('blurInput', onBlurInput as EventListener);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('selectItem', onSelectItem as EventListener);
        ref.removeEventListener('inputChanged', onInputChanged as EventListener);
        ref.removeEventListener('inputSubmit', onInputSubmitted as EventListener);
        ref.removeEventListener('clear', onClearItem as EventListener);
        ref.removeEventListener('blurInput', onBlurInput as EventListener);
      }
    };
  }, [picker, onSelectItem, onClearItem, onInputChanged, onInputSubmitted, onBlurInput]);

  return (
    <sg-picker
      classes={className}
      disabled={disabled}
      hide-no-result-message
      icon={icon}
      input-id={inputId}
      key-path="value"
      label-path="label"
      mode="filter"
      no-clear={noClear}
      no-icon={noIcon}
      no-typing-hint-message
      placeholder={placeholderText}
      readonly={readonly}
      ref={picker}
      single-select
      size={size}
      toggle-button={showToggleButton}
    />
  );
};
