import { ErrorState } from '@/components/shared/ErrorState';
import type { FC } from 'react';

export const NotFoundPage: FC = () => {
  return (
    <ErrorState
      color="danger"
      icon="web_asset_off"
      messages={[
        'The page that you are looking for cannot be found,',
        'please check your URL and try again.',
      ]}
      showContactUsButton
      showGoBackButton
      title="Page not found"
    />
  );
};
