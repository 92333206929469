import { getConfig } from '@/config/config';
import type { HttpConfig, HttpRequestMiddleware } from '@/utils/http/httpClient.models';
import urlJoin from 'url-join';

export const webApiBaseUrlHttpRequestMiddleware: HttpRequestMiddleware = (
  config: HttpConfig,
): HttpConfig => {
  if (!config.url.startsWith('/')) return config;

  const appConfig = getConfig();
  return { ...config, url: urlJoin(appConfig.api.base, config.url) };
};
