import type { HttpErrorResponse, TypedResults } from '@/models';

export interface QueriesState {
  suggestions?: TypedResults<string>;
  fetchSuggestionsError?: HttpErrorResponse;
}

export const initialQueriesState: QueriesState = {
  suggestions: undefined,
};
