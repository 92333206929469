import { initialQueriesState, type QueriesState } from './queries';
import { initialScriptsState, type ScriptsState } from './scripts/scripts.model';
import { initialSessionsState, type SessionsState } from './sessions';
import { initialUsersState, type UsersState } from './users';

export interface AppState {
  queries: QueriesState;
  scripts: ScriptsState;
  sessions: SessionsState;
  users: UsersState;
}

export const initialAppState: AppState = {
  queries: initialQueriesState,
  scripts: initialScriptsState,
  sessions: initialSessionsState,
  users: initialUsersState,
};
