import { useAppSelector } from '@/store/app.hooks';
import { useSamples } from '../useConfig';
import { useMemo } from 'react';
import { getCurrentUser } from '@/store/users';
import type { AppConfig } from '@/types/AppConfig';

export const useSampleScripts = (): AppConfig['samples']['scripts'] => {
  const user = useAppSelector(getCurrentUser);
  const scripts = useSamples('scripts');

  const filteredScripts = useMemo(() => {
    return scripts.filter(s => !s.internalOnly || (s.internalOnly && user?.isSgGroupUser));
  }, [scripts, user]);

  return filteredScripts;
};
