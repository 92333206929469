import type { AppState } from './app.model';
import { sessionsSlice } from './sessions/sessions.slice';
import { combineReducers, type Reducer, type ReducersMapObject } from '@reduxjs/toolkit';
import { usersSlice } from './users';
import { queriesSlice } from './queries';
import { scriptsSlice } from './scripts/scripts.slice';
import type { AppActions } from './app.actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer: Reducer<AppState, AppActions, any> = combineReducers<
  ReducersMapObject<AppState, AppActions>
>({
  queries: queriesSlice.reducer,
  scripts: scriptsSlice.reducer,
  sessions: sessionsSlice.reducer,
  users: usersSlice.reducer,
});
