import type { IntlKey } from '@/locale';
import { type ComponentPropsWithoutRef, type ElementType, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';

const NonExistId = v4();

type FormattedTextProps<T extends ElementType> = {
  as?: T;
  id?: IntlKey;
  defaultMessage?: string | null;
  className?: string;
} & ComponentPropsWithoutRef<T>;

export function Formatted<T extends ElementType>({
  as,
  id,
  defaultMessage,
  ...props
}: FormattedTextProps<T>) {
  const Component = as ?? Fragment;
  return (
    <Component {...props}>
      <FormattedMessage
        id={(id ?? NonExistId) as IntlKey}
        defaultMessage={defaultMessage ?? id ?? '-'}
      />
    </Component>
  );
}
