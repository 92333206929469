import { HelpCenter } from '@/components/sgwt';
import { MiniFooter } from '@/components/sgwt';
import type { FC } from 'react';

export const AppFooter: FC = () => (
  <footer>
    <HelpCenter />
    <MiniFooter />
  </footer>
);
