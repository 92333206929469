import { Button, Icon } from '@sgme/ui';
import type { FC } from 'react';
import { Formatted } from '../shared/Formatted';
import classNames from 'classnames';
import { DropdownButton } from '../shared/DropdownButton';
import { SampleScriptsButton } from '../SampleScriptsButton';

export interface SessionToolbarProps {
  sessionId: string;
  className?: string;
}

export const SessionToolbar: FC<SessionToolbarProps> = ({ sessionId, className }) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column flex-md-row align-items-start align-items-md-center',
        className,
      )}
    >
      <div className="d-flex align-items-center flex-fill">
        <h3>{sessionId}</h3>
        <button className="btn btn-icon btn-link ms-2" type="button">
          <Icon icon="edit" />
        </button>
      </div>

      <div className="ms-auto">
        <Button purpose="flat" color="secondary" iconPosition="start" size="lg" className="ms-2">
          <Icon icon="filter_list" />
          <Formatted id="Variable" as="span" className="d-none d-md-inline" />
        </Button>

        <DropdownButton
          buttonActiveColor="info"
          buttonIcon="save"
          buttonText="Save"
          className="ms-2"
          color="secondary"
          hideTextOnMobile
          purpose="flat"
          showToggle={false}
          size="lg"
          iconPosition="start"
        >
          <Button purpose="flat" color="secondary" size="lg" className="dropdown-item">
            <Formatted id="Save" />
          </Button>
          <Button purpose="flat" color="secondary" size="lg" className="dropdown-item">
            <Formatted id="Save as new session" />
          </Button>
        </DropdownButton>

        <Button purpose="flat" color="secondary" iconPosition="start" size="lg" className="ms-2">
          <Icon icon="autorenew" />
          <Formatted id="Rerun all" as="span" className="d-none d-md-inline" />
        </Button>

        <SampleScriptsButton />

        <Button purpose="flat" color="secondary" iconPosition="start" size="lg" className="ms-2">
          <Icon icon="zoom_out_map" />
          <Formatted id="Expand editors" as="span" className="d-none d-md-inline" />
        </Button>

        <button className="btn btn-lg btn-icon btn-link ms-2" type="button">
          <Icon icon="settings" />
        </button>
      </div>
    </div>
  );
};
