import { appRoutes } from '@/routes';
import type { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppContainer } from '../AppContainer';

const routes = appRoutes.map(route => ({
  ...route,
  element: <AppContainer>{route.element}</AppContainer>,
}));

const router = createBrowserRouter(routes, {
  basename: window.sgmeConfiguration.base,
});

export const AppRouter: FC = () => <RouterProvider router={router} />;
