/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AjaxResponse } from 'rxjs/ajax';
import type { HttpResponseMiddleware } from '../../httpClient.models';
import { sgwtConnect } from '@/utils/sgwtConnect';

export const tokenExpiredHttpResponseMiddleware: HttpResponseMiddleware = (
  response: AjaxResponse<any>,
): AjaxResponse<any> => {
  if (response.status === 401 && !sgwtConnect.isAuthorized()) sgwtConnect.requestAuthorization();

  return response;
};
