import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { initialSessionsState } from './sessions.model';
import { patchBy, patchObject } from '@/utils';
import type { HttpErrorResponse } from '@/models';

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState: initialSessionsState,
  // TODO: Unit test
  reducers: {
    newSessionRequested: (state, { payload }: PayloadAction<string>) =>
      patchObject(state, payload, { id: payload, scripts: [], saved: false }),

    newScriptAddedToSession: (
      state,
      { payload }: PayloadAction<{ sessionId: string; scriptId: string }>,
    ) =>
      patchObject(
        state,
        payload.sessionId,
        patchObject(
          state[payload.sessionId],
          'scripts',
          state[payload.sessionId].scripts.concat([payload.scriptId]),
        ),
      ),

    sessionSaveRequested: (state, { payload }: PayloadAction<string>) =>
      patchObject(
        state,
        payload,
        patchBy(state[payload], () => ({
          saved: false,
          saveStatus: 'IN_PROGRESS' as const,
          saveError: undefined,
        })),
      ),
    sessionSaved: (state, { payload }: PayloadAction<string>) =>
      patchObject(
        state,
        payload,
        patchBy(state[payload], () => ({ saved: true, saveStatus: 'SUCCESS' as const })),
      ),
    sessionSaveFailed: (
      state,
      { payload }: PayloadAction<{ sessionId: string; error: HttpErrorResponse }>,
    ) =>
      patchObject(
        state,
        payload.sessionId,
        patchBy(state[payload.sessionId], () => ({
          saved: false,
          saveStatus: 'ERROR' as const,
          saveError: payload.error,
        })),
      ),
  },
});

export const {
  newSessionRequested,
  newScriptAddedToSession,
  sessionSaveRequested,
  sessionSaved,
  sessionSaveFailed,
} = sessionsSlice.actions;
