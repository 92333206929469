import type { FC, PropsWithChildren } from 'react';
import { AppHeader } from '../AppHeader';
import { AppFooter } from '../AppFooter';

export const AppContainer: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className="app">
    <AppHeader />
    <main className="pt-4 px-4 pb-128px overflow-auto">{children}</main>
    <AppFooter />
  </div>
);
