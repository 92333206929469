import { Fragment, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from '@/widgets/widgets.ts';
import type { LocaleIsoCode } from '@/locale';
import allLocales, { defaultLocale } from '@/locale';

export const IntlProvider = ({ children }: { children: JSX.Element }) => {
  const [locale, setLocale] = useState<LocaleIsoCode>('en');

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();
    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, lang => {
        if (lang && Object.keys(allLocales).includes(lang.toLowerCase())) {
          setLocale(lang.toLowerCase() as LocaleIsoCode);
          document.querySelector('html')!.lang = lang;
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={allLocales[locale]}
      textComponent={Fragment}
    >
      {children}
    </ReactIntlProvider>
  );
};
