import { rootReducer } from './app.reducer';
import { initialAppState, type AppState } from './app.model';
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic$ } from './app.epics';
import { currentUserInfoRequested, currentUserPrefRequested } from './users';
import type { AppActions } from './app.actions';

const epicMiddleware = createEpicMiddleware<AppActions>();

export const appStore = configureStore<AppState, AppActions>({
  reducer: rootReducer,
  preloadedState: initialAppState,
  middleware: () => new Tuple(epicMiddleware),
});

epicMiddleware.run(rootEpic$);

dispatchInitialActions();

function dispatchInitialActions(): void {
  appStore.dispatch(currentUserInfoRequested());
  appStore.dispatch(currentUserPrefRequested());
}
