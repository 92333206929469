import { Formatted } from '@/components/shared/Formatted';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import type { IntlKey } from '@/locale';
import { Icon } from '@sgme/ui';
import classNames from 'classnames';
import styled from 'styled-components';
import { ScriptSearchBar } from '@/components/ScriptSearchBar';
import { useSamples, useSampleScripts, useSessionFromSearchParam } from '@/hooks';

const CardContainer = styled.div`
  gap: 1.5rem;
`;

const Card = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

export const HomePage: FC = () => {
  useSessionFromSearchParam();

  const scripts = useSampleScripts();
  const queries = useSamples('queries');

  return (
    <>
      <CardContainer className="d-flex flex-column flex-md-row">
        <Card className="card bg-lvl2">
          <div className="card-header p-4">
            <Formatted id="Create your own" as="h3" className="card-title display-4 m-0" />
          </div>
          <div className="card-body px-4 pb-4">
            <ScriptSearchBar id="home-page-script-search-bar" />

            <Formatted
              id="Write what you want inside the search bar, for example:"
              as="h6"
              className="mt-4"
            />

            <div className="mt-3">
              {queries.map((query, index) => (
                <Formatted id={query as IntlKey} key={index} as="div" />
              ))}
            </div>
          </div>
        </Card>

        <Card className="card bg-lvl2">
          <div className="card-header p-4">
            <Formatted
              id="Start from an existing script"
              as="h3"
              className="card-title display-4 m-0"
            />
          </div>
          <div className="card-body px-4 pb-4">
            {scripts.map(({ name, sessionId }, index) => (
              <Link
                key={index}
                // TODO: Use the /sessions/ID route instead
                to={`/home?sessionId=${sessionId}`}
                // to={`/sessions/${sessionId}`}
                className={classNames(
                  'btn btn-lg btn btn-default btn-icon-end btn-block flex-between',
                  { 'mt-1': index > 0 },
                )}
              >
                <Formatted id={name as IntlKey} as="span" className="fs-3 fw-semibold" />
                <Icon icon="arrow_forward" className="text-secondary" />
              </Link>
            ))}
          </div>
        </Card>

        <Card className="d-none d-lg-block" />
      </CardContainer>
      <Formatted
        id="The Python scripts are editable. Feel free to customize them for your needs."
        as="div"
        className="mt-4"
      />
    </>
  );
};
