import './App.scss';
import { IntlProvider, SgwtWidgetsProvider } from '@/context';
import { Provider } from 'react-redux';
import { appStore } from '@/store';
import { AppRouter } from './layout/AppRouter/AppRouter';

export function App() {
  return (
    <Provider store={appStore}>
      <IntlProvider>
        <SgwtWidgetsProvider>
          <AppRouter />
        </SgwtWidgetsProvider>
      </IntlProvider>
    </Provider>
  );
}
