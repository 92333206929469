import type { HttpErrorResponse } from '@/models';
import type { User } from '@/models/Users/User';
import type { UserPreference } from '@/models/Users/UserPreference';

export interface UsersState {
  current?: User;
  preferences?: UserPreference;
  sessionId?: string;
  fetchUserError?: HttpErrorResponse;
  fetchPreferencesError?: HttpErrorResponse;
}

export const initialUsersState: UsersState = {
  current: undefined,
  preferences: undefined,
  sessionId: undefined,
  fetchUserError: undefined,
  fetchPreferencesError: undefined,
};
