import { useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import type {
  SgwtAccountCenterHTMLElement,
  SgwtHelpCenterHTMLElement,
  SgwtMiniFooterHTMLElement,
} from '@/types/sgwt-widgets';
import { SgwtWidgetsContext, type SgwtWidgetsContextValue } from './SgwtWidgetsContext';

export const SgwtWidgetsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accountCenter, setAccountCenter] = useState<SgwtAccountCenterHTMLElement>();
  const [helpCenter, setHelpCenter] = useState<SgwtHelpCenterHTMLElement>();
  const [miniFooter, setMiniFooter] = useState<SgwtMiniFooterHTMLElement>();

  const value = useMemo<SgwtWidgetsContextValue>(
    () => ({
      accountCenter,
      helpCenter,
      miniFooter,
      setAccountCenter,
      setHelpCenter,
      setMiniFooter,
    }),
    [accountCenter, helpCenter, miniFooter],
  );

  return <SgwtWidgetsContext.Provider value={value}>{children}</SgwtWidgetsContext.Provider>;
};
