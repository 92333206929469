import { useEffect } from 'react';
import { useGoToSession } from '../useGoToSession';
import { useSearchParam } from '../useSearchParam';
import type { SearchParamKey } from '@/models';

export const useSessionFromSearchParam = (key: SearchParamKey = 'sessionId'): void => {
  const sessionId = useSearchParam(key);
  const goToSession = useGoToSession();

  useEffect(() => {
    if (sessionId === undefined) {
      return;
    }
    goToSession(sessionId);
  }, [sessionId, goToSession]);
};
