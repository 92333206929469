import { SgwtWidgetsContext } from '@/context';
import { useConfig } from '@/hooks';
import type { SgwtHelpCenterHTMLElement } from '@/types/sgwt-widgets';
import { useContext, useRef, useEffect } from 'react';

export function HelpCenter(): JSX.Element {
  const {
    helpCenter: { subject },
  } = useConfig();

  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtHelpCenterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    sgwtWidgets.setHelpCenter(ref.current);
  }, [sgwtWidgets]);

  return (
    <aside>
      <sgwt-help-center
        allow-screenshot
        application-id={import.meta.env.VITE_ID as string}
        mail-subject={subject}
        sg-connect-support="sg-connect-v2"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ref={ref}
      />
    </aside>
  );
}
