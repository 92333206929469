import { queriesApiConnector, type QueriesAPI } from '@/api/queries';
import { httpClient } from '@/utils/http';
import { combineEpics } from 'redux-observable';
import type { VhsEpic } from '../store';
import { switchMap, map, catchError, of } from 'rxjs';
import { isOfType } from '../store.utils';
import {
  querySuggestionsFetched,
  querySuggestionsFetchFailed,
  querySuggestionsRequested,
} from './queries.slice';
import type { HttpErrorResponse } from '@/models';

export const querySuggestionsRequestedEpic$ =
  (api$: QueriesAPI): VhsEpic =>
  actions$ =>
    actions$.pipe(
      isOfType<ReturnType<typeof querySuggestionsRequested>>(querySuggestionsRequested.type),
      switchMap(({ payload }) =>
        api$.getSuggestions$(payload).pipe(
          map(res => querySuggestionsFetched(res.payload)),
          catchError((err: HttpErrorResponse) => of(querySuggestionsFetchFailed(err))),
        ),
      ),
    );

const queriesApi$ = queriesApiConnector(httpClient);

export const queriesEpics$ = combineEpics(querySuggestionsRequestedEpic$(queriesApi$));
