import { SessionToolbar } from '@/components/SessionToolbar';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { NotFoundPage } from '../NotFound';

export const SessionsPage: FC = () => {
  const { id } = useParams();

  return id === undefined ? (
    <NotFoundPage />
  ) : (
    <div>
      <SessionToolbar sessionId={id} className="mb-3" />
      SESSION {id}
    </div>
  );
};
