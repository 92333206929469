import type { HttpGetConfig, HttpRequestMiddleware } from '../../httpClient.models';
import queryString from 'query-string';

// TODO: Unit test
export const queryParamsHttpRequestMiddleware: HttpRequestMiddleware = (
  config: HttpGetConfig,
): HttpGetConfig => {
  const { queryParams: params, ...requestConfig } = config;
  if (params === undefined) return requestConfig;

  const query = queryString.stringify(params, { skipNull: true, skipEmptyString: true });
  return {
    ...requestConfig,
    url: `${requestConfig.url}?${query}`,
  };
};
