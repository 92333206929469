export const patchObject = <T, K extends keyof T>(obj: T, key: K, value: T[K]): T => {
  return { ...obj, [key]: value };
};

// TODO: Unit test
export const patchBy = <T>(obj: T, patcher: Patcher<T>): T => {
  return {
    ...obj,
    ...patcher(obj),
  };
};
