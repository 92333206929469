import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { initialScriptsState } from './scripts.model';
import { patchBy, patchObject } from '@/utils';
import type { ErrorScriptResult, Script, SuccessScriptResult } from '@/models';
import type { ScriptSuggestion } from '@/models/Responses/ScriptSuggestion';
import { v4 as uuidv4 } from 'uuid';

export const scriptsSlice = createSlice({
  name: 'scripts',
  initialState: initialScriptsState,
  // TODO: Unit test
  reducers: {
    scriptSuggestionRequested: (state, { payload }: PayloadAction<{ query: string }>) => {
      const id = uuidv4();
      const newScript: Script = {
        id,
        name: payload.query,
        status: 'Loading',
      };
      return patchObject(state, id, newScript);
    },
    scriptSuggestionFetched: (
      state,
      { payload }: PayloadAction<ScriptSuggestion & { id: string }>,
    ) => {
      return patchObject(
        state,
        payload.id,
        patchBy(state[payload.id], () => ({
          status: 'Loaded' as const,
          python: payload.python,
          resultVariableName: 'result',
        })),
      );
    },
    scriptSuggestionFetchFailed: (
      state,
      { payload }: PayloadAction<{ id: string; error: string }>,
    ) =>
      patchObject(
        state,
        payload.id,
        patchBy(state[payload.id], () => ({
          status: 'Error' as const,
          graphs: payload.error,
        })),
      ),

    scriptRunRequested: (state, { payload }: PayloadAction<string>) =>
      patchObject(state, payload, patchObject(state[payload], 'status', 'Running')),
    scriptRunSucceeded: (state, { payload }: PayloadAction<SuccessScriptResult>) =>
      patchObject(
        state,
        payload.requestId,
        patchBy(state[payload.requestId], () => ({
          status: 'Success' as const,
          graphs: payload.graphs,
        })),
      ),
    scriptRunFailed: (state, { payload }: PayloadAction<ErrorScriptResult>) =>
      patchObject(
        state,
        payload.requestId,
        patchBy(state[payload.requestId], () => ({
          status: 'Error' as const,
          graphs: payload.errorMessage,
        })),
      ),
  },
});

export const {
  scriptSuggestionRequested,
  scriptSuggestionFetched,
  scriptSuggestionFetchFailed,
  scriptRunRequested,
  scriptRunSucceeded,
  scriptRunFailed,
} = scriptsSlice.actions;
